import React from 'react'
import { View, TouchableOpacity } from 'react-native'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import tailwind, { getColor } from 'tailwind-rn'

export default function MyModal({
  isVisible = false,
  showCloseBtn = true,
  toggle,
  children,
}) {
  if (!isVisible) return <></>
  return (
    <View
      style={tailwind(
        'w-full h-full absolute p-12 pt-40 bg-black bg-opacity-75 z-50'
      )}
    >
      <View style={tailwind('p-4 bg-white rounded-lg')}>
        {showCloseBtn && (
          <TouchableOpacity
            style={tailwind('rounded-full text-right -m-2 flex items-end')}
            onPress={toggle}
          >
            <MaterialCommunityIcons
              name="window-close"
              size={24}
              color={getColor('teal-800')}
            />
          </TouchableOpacity>
        )}
        <View style={tailwind('')}>{children}</View>
      </View>
    </View>
  )
}
