import tailwind, { getColor } from 'tailwind-rn'
import { SimpleLineIcons } from '@expo/vector-icons'
import Logo from '../../assets/logo.png'
import { useAuthDispatch } from '../contexts/AuthContext'
import { Text, TouchableOpacity, View, Image } from 'react-native'
import React from 'react'
import { signOut } from '../utils/common'

export default function HomeHeader() {
  const authDispatch = useAuthDispatch()

  return (
    <View style={tailwind('bg-white rounded-b-lg px-4 pt-5 pb-4')}>
      <View style={tailwind('flex-row items-center justify-between')}>
        <Image
          source={Logo}
          style={{
            width: '50%',
            height: 50,
            resizeMode: 'contain',
          }}
        />
        <View style={tailwind('flex flex-row items-center')}>
          <TouchableOpacity
            onPress={async () => {
              await signOut()
              authDispatch({ type: 'SIGN_OUT' })
            }}
            style={tailwind(
              'border border-red-500 p-2 rounded-md flex-row items-center'
            )}
          >
            <SimpleLineIcons
              name="logout"
              size={15}
              color={getColor('red-500')}
              style={tailwind('mr-2 font-bold')}
            />
            <Text style={tailwind('text-red-500 uppercase font-bold')}>
              Sair
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  )
}
