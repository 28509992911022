import React, { useMemo } from 'react'
import { Text, View } from 'react-native'
import { Picker } from '@react-native-community/picker'
import tailwind, { getColor } from 'tailwind-rn'
import { FontAwesome } from '@expo/vector-icons'
import { monthYear } from '../utils/common'

export default function YearPicker({ style, onChange, currentYearMonth }) {
  const years = useMemo(() => {
    const currentYear = [
      new Date().getUTCFullYear(),
      new Date().getUTCFullYear() - 1,
    ]
    return new Array(currentYear[0] - currentYear[1] + 1)
      .fill(null)
      .map((_, i) => currentYear[1] + i)
  }, [])

  const months = useMemo(() => {
    const data = Object.entries(monthYear).map(([v, k]) => ({
      monthName: v.charAt(0).toUpperCase() + v.slice(1),
      monthNumber: Number(k),
    }))
    return data
  })

  return (
    <View style={style}>
      <View style={tailwind('flex-row items-center px-2')}>
        <FontAwesome name="calendar" size={15} color={getColor('gray-500')} />
        <Text style={tailwind('text-gray-500 ml-2')}>Mês/Ano</Text>
      </View>
      <View>
        <Picker
          onValueChange={(value) =>
            onChange({ year: value, month: currentYearMonth.month })
          }
          prompt={'Ano'}
          selectedValue={currentYearMonth.year}
          style={tailwind('text-teal-800')}
        >
          {years.map((year) => (
            <Picker.Item key={year} label={year.toString()} value={year} />
          ))}
        </Picker>
        <Picker
          onValueChange={(value) =>
            onChange({ year: currentYearMonth.year, month: value })
          }
          prompt={'Mês'}
          selectedValue={currentYearMonth.month}
          style={tailwind('text-teal-800 font-bold')}
        >
          {months.map((d) => (
            <Picker.Item
              key={d.monthNumber}
              label={d.monthName}
              value={d.monthNumber}
            />
          ))}
        </Picker>
      </View>
    </View>
  )
}
