import AsyncStorage from '@react-native-async-storage/async-storage'
import { PDFDocument } from 'pdf-lib'
import folhadePontoTemplate from '../utils/folhadepontoTemplateBase64'
import { Platform } from 'react-native'
import * as Sharing from 'expo-sharing'
import * as FileSystem from 'expo-file-system'

export const BASE_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3000'
    : 'https://api.ecopelserv.com'

export async function signIn(credentials) {
  console.log(BASE_URL)
  const res = await fetch(`${BASE_URL}/api/login`, {
    body: JSON.stringify(credentials),
    headers: { 'Content-Type': 'application/json' },
    method: 'POST',
  })
  const resData = await res.json()

  if (!resData || !resData.userToken) throw new Error('Falha de Autentificação')

  await AsyncStorage.setItem('@userToken', resData.userToken)
  console.log('secao 1')
  return resData
}

export async function resetPassword(data, userToken) {
  console.log(BASE_URL)
  const { newPassword, newPasswordConfirmation } = data
  if (!newPassword || !newPasswordConfirmation)
    throw new Error('Digite todos os campos para continuar')
  if (newPassword !== newPasswordConfirmation)
    throw new Error('As senhas em ambos os campos precisam ser iguais')

  // enviar ao servidor
  const res = await fetch(`${BASE_URL}/api/reset-password`, {
    method: 'POST',
    headers: {
      Authorization: userToken,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  const resData = await res.json()

  if (!resData || !resData.passwordReseted || !resData.userToken)
    throw new Error('Não foi possível alterar a senha')

  await AsyncStorage.setItem('@userToken', resData.userToken)

  return resData.userToken
}

export async function signOut() {
  await AsyncStorage.removeItem('@userToken')
}

export const cpfMask = (value) => {
  return value
    .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
    .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1') // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
}

export const monthYear = {
  janeiro: '01',
  fevereiro: '02',
  março: '03',
  abril: '04',
  maio: '05',
  junho: '06',
  julho: '07',
  agosto: '08',
  setembro: '09',
  outubro: '10',
  novembro: '11',
  dezembro: '12',
}
export const numberToMonth = {
  '01': 'JANEIRO',
  '02': 'FEVEREIRO',
  '03': 'MARÇO',
  '04': 'ABRIL',
  '05': 'MAIO',
  '06': 'JUNHO',
  '07': 'JULHO',
  '08': 'AGOSTO',
  '09': 'SETEMBRO',
  10: 'OUTUBRO',
  11: 'NOVEMBRO',
  12: 'DEZEMBRO',
}
export const numberToMonth2 = {
  1: 'Janeiro',
  2: 'Fevereiro',
  3: 'Março',
  4: 'Abril',
  5: 'Maio',
  6: 'Junho',
  7: 'Julho',
  8: 'Agosto',
  9: 'Setembro',
  10: 'Outubro',
  11: 'Novembro',
  12: 'Dezembro',
}

export async function generateFolhadePontoPDF(item) {
  const pdfDoc = await PDFDocument.load(folhadePontoTemplate)
  const form = pdfDoc.getForm()

  form.getField('Empregador').setText('ECOPEL SERVIÇOS EIRELI')
  form.getField('CNPJ').setText('24.965.271/0001-40')
  form
    .getField('Endereco')
    .setText('R QUEOPES, SALA 407 NUMERO 13, JARDIM RENASCENCA - SÃO LUÍS, MA')
  form.getField('Periodo').setText(item.periodo)
  form.getField('Empregado').setText(item.nomeColaborador)
  form.getField('Lotacao').setText(item.lotacao)

  form.getFields().forEach((f) => f.enableReadOnly())

  if (Platform.OS === 'web') {
    const pdfBytes = await pdfDoc.save()
    const blob = new Blob([pdfBytes], { type: 'application/pdf' })
    const url = URL.createObjectURL(blob)
    window.open(url)
  } else {
    const newPDFBase64 = await pdfDoc.saveAsBase64({ dataUri: false })
    const pdfPath = FileSystem.cacheDirectory + 'folha-de-frequencia.pdf'
    await FileSystem.writeAsStringAsync(pdfPath, newPDFBase64, {
      encoding: FileSystem.EncodingType.Base64,
    })
    await Sharing.shareAsync(pdfPath)
  }
}

export async function generateEmailConfirmationCode(emailAddress, userToken) {
  try {
    const res = await fetch(`${BASE_URL}/api/emailConfirmation`, {
      method: 'POST',
      body: JSON.stringify({ emailAddress }),
      headers: {
        Authorization: userToken,
        'Content-Type': 'application/json',
      },
    })
    const { success } = await res.json()
    return success
  } catch (e) {
    console.log(e)
    return false
  }
}

export async function checkEmailConfirmationCode(
  emailConfirmationCode,
  userToken
) {
  try {
    const res = await fetch(`${BASE_URL}/api/emailConfirmation`, {
      method: 'PUT',
      body: JSON.stringify({ emailConfirmationCode }),
      headers: {
        Authorization: userToken,
        'Content-Type': 'application/json',
      },
    })
    const { success } = await res.json()
    console.log('success', success)
    return success
  } catch (e) {
    console.log(e)
    return false
  }
}

export function validateEmail(email) {
  return /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)
}

export async function generateResetPasswordCode(emailAddress) {
  try {
    const res = await fetch(
      `${BASE_URL}/api/reset-password?emailAddress=${emailAddress}`,
      { method: 'GET' }
    )
    const { success, message = '' } = await res.json()
    return { success, message }
  } catch (e) {
    console.log(e)
    return { success: false, message: e.message }
  }
}

export async function confirmResetPassword(data) {
  try {
    const res = await fetch(`${BASE_URL}/api/reset-password`, {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: { 'Content-Type': 'application/json' },
    })
    const { success, message = '' } = await res.json()
    return { success, message }
  } catch (e) {
    console.log(e)
    return { success: false, message: e.message }
  }
}

export function fetchWithToken(url, userToken) {
  return fetch(url, {
    method: 'GET',
    headers: { Authorization: userToken },
  }).then((r) => r.json())
}
