import React, { createContext, useContext, useEffect, useReducer } from 'react'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { BASE_URL } from '../utils/common'

const AuthStateContext = createContext()
const AuthDispatchContext = createContext()

function authReducer(prevState, action) {
  switch (action.type) {
    case 'RESTORE_TOKEN':
      return {
        ...prevState,
        userToken: action.token,
        isLoading: false,
        isFirstLogin: action.isFirstLogin,
      }
    case 'RESET_TOKEN':
      return {
        ...prevState,
        userToken: action.newToken,
        isFirstLogin: false,
        isLoading: false,
      }
    case 'SIGN_IN':
      return {
        ...prevState,
        isSignout: false,
        isLoading: false,
        userToken: action.token,
        isFirstLogin: action.isFirstLogin,
      }
    case 'SIGN_OUT':
      return {
        ...prevState,
        isSignout: true,
        userToken: null,
      }
    case 'SET_VINCULO':
      return {
        ...prevState,
        codigoColaborador: action.codigoColaborador,
      }
    default:
      throw new Error('Action type must be defined')
  }
}

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(authReducer, {
    isLoading: true,
    isSignout: false,
    userToken: null,
  })

  useEffect(() => {
    const loadToken = async () => {
      try {
        const userToken = await AsyncStorage.getItem('@userToken')
        if (!userToken) throw new Error('userToken must be not null')
        // validar userToken no FaunaDB
        const res = await fetch(`${BASE_URL}/api/login`, {
          method: 'PUT',
          headers: {
            Authorization: `${userToken}`,
            Accept: 'application/json',
          },
        })

        const resData = await res.json()

        if (resData.isLoggedIn)
          dispatch({
            type: 'RESTORE_TOKEN',
            token: userToken,
            isFirstLogin: resData.isFirstLogin,
          })
      } catch (e) {
        console.log(e)
      }
    }

    loadToken()
  }, [])

  return (
    <AuthStateContext.Provider value={state}>
      <AuthDispatchContext.Provider value={dispatch}>
        {children}
      </AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  )
}

export function useAuthState() {
  const context = useContext(AuthStateContext)
  if (context === undefined)
    throw new Error('useAuthState must be used within a AuthProvider')
  return context
}

export function useAuthDispatch() {
  const context = useContext(AuthDispatchContext)
  if (context === undefined)
    throw new Error('useAuthDispatch must be used within a AuthProvider')
  return context
}
